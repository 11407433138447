// src/components/Chat.tsx
import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Grid, /*CircularProgress,*/ LinearProgress } from "@mui/material";
import Message from "./Message";
import OpenAI from "openai";
import { MessageDto } from "../models/MessageDto";
import { useLocation } from 'react-router-dom';

const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>(new Array<MessageDto>());
  const [input, setInput] = useState<string>("");
  const [threadId, setThreadId] = useState<any>(null);
  const [assistantId, setAssistantId] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isOk, setIsOk] = useState<boolean>(false);

  const registrarNuevosMensajes = async () => {
    const JSONdata = JSON.stringify({ threadID: threadId });
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSONdata,
    };
    await fetch(process.env.REACT_APP_API_URL + 'chat/messages', options);
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true); // Asegúrate de que initChatBot solo se llame una vez

      const params = new URLSearchParams(location.search);
      const aid = params.get('aid');
      let metadata: any = {};

      for (const [key, value] of params) {
        if(key !== "aid"){
          metadata[key] = value;
        }
      }
      if (aid) {
        initChatBot(aid, metadata);
      }
    }
  }, [location.search, isInitialized]);

  useEffect(() => {
    if (openai && isOk) {
      handleSendMessage();
    }
  }, [openai, isOk]);


  const initChatBot = async (aid: string, metadata: any) => {
    const JSONdata = JSON.stringify({
      aid: aid,
      metadata: metadata,
    })
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSONdata,
    }

    const response = await fetch(process.env.REACT_APP_API_URL + 'chat/init', options)

    const result = await response.json()
    if(result.code === 0){
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });
      setThreadId(result.data.threadID);
      setAssistantId(aid);
      setIsOk(true);
      setOpenai(openai);
    }
  };

  const createNewMessage = (content: string, isUser: boolean) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
    setIsWaiting(true);

    if(input !== ""){
      messages.push(createNewMessage(input, true));
      setMessages([...messages]);
      setInput("");
  
  
      // Send a message to the thread
      await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: input,
      });
    }

    // Run the assistant
    const run = await openai.beta.threads.runs.create(threadId, {
        assistant_id: assistantId,
    });

    // Create a response
    let response = await openai.beta.threads.runs.retrieve(threadId, run.id);

    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
      console.log("waiting...");
      await new Promise((resolve) => setTimeout(resolve, 2000));
      response = await openai.beta.threads.runs.retrieve(threadId, run.id);
    }

    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(threadId, { order: 'desc', run_id: run.id, limit: 1 });

    // Find the last message for the current run
    const lastMessage = (messageList && messageList.data && messageList.data.length > 0 ? messageList.data[0] : null);

    setIsWaiting(false);

    // Print the last message coming from the assistant
    if (lastMessage) {
      console.log(lastMessage.content[0]["text"].value);
      setMessages([...messages, createNewMessage(lastMessage.content[0]["text"].value, false)]);
      registrarNuevosMensajes();
    }
  };

  // detect enter key and send message
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <Container>
      <br/>
      {!isOk && <p>aid missing</p>}
      {isOk && 
      <Grid container direction="column" spacing={2} paddingBottom={5}>
        {messages.map((message, index) => (
          <Grid item alignSelf={message.isUser ? "flex-end" : "flex-start"} key={index}>
            <Message key={'m' + index} message={message} />
          </Grid>
        ))}
        {isWaiting && <LinearProgress color="inherit" />}
        {!isWaiting && (
          <Grid item>
            <TextField
                label="Escriba su mensaje"
                variant="outlined"
                disabled={isWaiting}
                fullWidth
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyPress}
            />
            <Button variant="contained" color="primary" onClick={handleSendMessage} disabled={isWaiting}>
              Enviar
            </Button>
          </Grid>
        )}
      </Grid>}
    </Container>
  );
};

export default Chat;